@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
    font-family: 'Charter';
    src: url('./fonts/Charter.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */

html {
    scroll-behavior: smooth;
}

html {
    direction: ltr;
    overflow-x: hidden;
}

html[lang="ar"] {
    direction: rtl;
    overflow-x: hidden;
}

body {
    font-family: 'PT Sans', sans-serif;
    background-image: url(./assest/images/background-image.png);
    background-size: cover;
    overflow-x: hidden;
}

/*
body[lang="ar"] {
    direction: rtl;
} */


body::-webkit-scrollbar {
    width: 7px;
}

body::-webkit-scrollbar-track {
    background: #fff;
}

body::-webkit-scrollbar-thumb {
    background-image: linear-gradient(360deg, rgb(0 119 182 / 100%), rgb(0 147 221 / 100%));
}

/* @media (max-width: 900px) {
    body::-webkit-scrollbar {
        width: 0px;
    }
} */



/* Start Container */

.container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

/* End Container */

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/* Start Bg  */

.bgLinearFull {
    background-image: linear-gradient(360deg, rgb(0, 119, 182), rgb(0 147 221 / 100%));
}

.bgLinearH {
    background-image: linear-gradient(360deg, rgb(0 119 182 / 100%), rgb(0 147 221 / 50%));
}

.bgLinear {
    background-image: linear-gradient(360deg, rgb(0 119 182 / 50%), rgb(0 147 221 / 50%));
}

.bgLinearTwo {
    background-image: linear-gradient(360deg, rgb(0 119 182 / 20%), rgb(0 147 221 / 20%));
}

/* End Bg */

/* Start GoTop */

.goTop {
    position: fixed;
    display: none;
    bottom: 20px;
    right: 20px;
    z-index: 10;
}

.goTop span {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.251);
    cursor: pointer;
    transition: 0.2s;
}

.goTop span i {
    transition: 0.2s;
    font-size: 1.1em;
}

.goTop span:hover {
    background-color: #0093dd;
}

.goTop span:hover i {
    color: #fff;
}

/* Start Footer */

footer {
    font-family: "Cairo", sans-serif;
    background-image: url(./assest/images/background-image.png);
    background-size: cover;
}

/* End Footer */

/* Start Main Title */

.main-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 35px;
    border-bottom: 5px solid #0093dd;
    width: fit-content;
    margin: 0 auto;
    color: white;
    margin-bottom: 25px;
    transition: 0.5s;
    text-align: center;
}

@media (max-width: 768px) {
    .main-title {
        font-size: 27px;
    }
}

/* End Main Title */

.hearder-pages {
    height: 50vh;
}

.statistics-section {
    height: 45vh;
    background-image: url(./assest/global-connections.jpg);
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

@media (max-width: 768px) {
    .statistics-section {
        height: 65vh;
    }
}


.landing-page {
    height: 86vh;
    border-bottom: 6px solid #0093dd;
}

.animtion-pages {
    animation-name: rot;
    animation-duration: 2.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes rot {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* 3 */

.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    outline: none;
}

.btn-3 {
    background: rgb(0, 172, 238);
    background: linear-gradient(0deg, rgba(0, 172, 238, 1) 0%, rgba(2, 126, 251, 1) 100%);
    width: 130px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;

}

.btn-3 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.btn-3:before,
.btn-3:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: rgba(2, 126, 251, 1);
    transition: all 0.3s ease;
}

.btn-3:before {
    height: 0%;
    width: 2px;
}

.btn-3:after {
    width: 0%;
    height: 2px;
}

.btn-3:hover {
    background: transparent;
    box-shadow: none;
}

.btn-3:hover:before {
    height: 100%;
}

.btn-3:hover:after {
    width: 100%;
}

.btn-3 span:hover {
    color: rgba(2, 126, 251, 1);
}

.btn-3 span:before,
.btn-3 span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background: rgba(2, 126, 251, 1);
    transition: all 0.3s ease;
}

.btn-3 span:before {
    width: 2px;
    height: 0%;
}

.btn-3 span:after {
    width: 0%;
    height: 2px;
}

.btn-3 span:hover:before {
    height: 100%;
}

.btn-3 span:hover:after {
    width: 100%;
}

/* Start Animation */

.js-scroll {
    opacity: 0;
}

.js-scroll.scrolled {
    opacity: 1;
}

.scrolled.fade-left {
    animation: fadeleft 800ms ease forwards;
}

.scrolled.fade-right {
    animation: faderight 800ms ease forwards;
}

.scrolled.fade-top {
    animation: fadetop 800ms ease forwards;
}

.scrolled.fade-btm {
    animation: fadebtm 800ms ease forwards;
}

@keyframes fadeleft {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes faderight {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadetop {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadebtm {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.btmAni2000 {
    opacity: 0;
    transform: translateY(-200px);
    animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.5s forwards;
}

.btmAni2500 {
    opacity: 0;
    transform: translateY(200px);
    animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.4s forwards;
}

.btmAni3000 {
    opacity: 0;
    transform: translateY(200px);
    animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s forwards;
}

.btmAni20002 {
    opacity: 0;
    transform: translateY(200px);
    animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s forwards;
}

.btmAni25002 {
    opacity: 0;
    transform: translateY(200px);
    animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s forwards;
}

.btmAni30002 {
    opacity: 0;
    transform: translateY(200px);
    animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1s forwards;
}

@keyframes btmAni {
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


/* Start Menu */

.menu {
    display: none;
    flex-direction: column;
    position: relative;
    height: 80px;
    width: 35px;
    border: none;
    outline: none;
    background-color: transparent;
    /* margin-right: 30px; */
    cursor: pointer;
    justify-content: center;
    gap: 2px;
}

@media (max-width: 768px) {
    .menu {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
}

.menu span {
    display: block;
    width: 27px;
    height: 2px;
    margin: 3px auto;
    background-color: white;
    transition: all 0.3s ease-in-out;
}

.menu.active span:nth-child(1) {
    transform: rotate(45deg) translate(-1px, 15px);
    background-color: red;
}

.menu.active span:nth-child(2) {
    opacity: 0;
}

.menu.active span:nth-child(3) {
    transform: rotate(-45deg) translate(-1px, -15px);
    background-color: red;
}


/* Start Responsev Mobile */


@media (max-width: 768px) {
    .lang {
        display: none !important;
    }
}


@media (max-width: 768px) {
    .f-col {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .d-flex {
        display: flex;
    }
}

@media (max-width: 768px) {
    .d-none {
        display: none;
    }
}

@media (max-width: 768px) {
    .width-100 {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .height-100 {
        height: 100%;
    }
}

@media (max-width: 768px) {
    .t-center {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .f-20 {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .f-34 {
        font-size: 34px;
    }
}

@media (max-width: 768px) {
    .f-29 {
        font-size: 29px;
    }
}

@media (max-width: 768px) {
    .f-title {
        font-size: 2.5rem;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .ij {
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .h-250 {
        height: 250px;
    }
}

@media (max-width: 768px) {
    .g-15 {
        gap: 15px;
    }
}

/* AR CSS */

html[lang="ar"] .ang-1 {
    border-right: 1px solid;
    border-left: 0;
    border-bottom: 0;
}

html[lang="ar"] .ang-2 {
    border-right: 1px solid;
    border-left: 0;
    border-bottom: 1px solid;
}

html[lang="ar"] .ang-3 {
    border-right: 0;
    border-left: 1px solid;
}

html[lang="ar"] .ang-4 {
    border-right: 0;
    border-left: 1px solid;
}

html[lang="ar"] .border-left-none {
    padding-right: 10px;
    padding-left: 0;
    border-left: 0;
    border-right: 12px solid #0093dd;
}

html[lang="ar"] .padding-right-10 {
    padding-right: 10px;
}

html[lang="ar"] .transformRotate {
    transform: rotate(180deg);
}

html[lang="ar"] .dir {
    direction: ltr;
}


/* Start Loader */


.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 40;
    width: 100%;
    height: 100%;
    background-color: #0e2e3e;
    animation: hide 400ms ease 2500ms forwards;
}

.loader img:nth-child(2) {
    width: 80px;
    margin-left: 25px;
    transform: translateY(-20px);
}

@keyframes hide {
    100% {
        top: 100%;
    }
}

.loaderLogo {
    opacity: 0;
    animation: loader 2s linear 0s infinite forwards;
}

.loaderLogo img {
    width: 350px;
}

@keyframes loader {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}